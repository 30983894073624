import React, { useState } from 'react';
import '../css/DonatePay.css'; // Assuming you're adding styles in a separate CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const Donate = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        donationType: 'Nakit Bağışı',
        amount: '',
        currency: 'TRY',
        name: '',
        surname: '',
        city: '',
        district: '',
        phone: '',
        email: '',
        cardName: '',
        cardNumber: '',
        expiryDate: new Date().toLocaleDateString(),
        securityCode: ''
    });
    const [errors, setErrors] = useState({});

    const nextStep = () => setStep((prevStep) => prevStep + 1);
    const prevStep = () => setStep((prevStep) => prevStep - 1);

    const validateStep1 = () => {
        if (!formData.amount) {
            setErrors({ amount: 'Lütfen bir bağış paketi seçin.' });
            return false;
        }
        setErrors({});
        return true;
    };

    const validateStep2 = () => {
        const tempErrors = {};
        if (!formData.name) tempErrors.name = 'Ad gerekli.';
        if (!formData.surname) tempErrors.surname = 'Soyad gerekli.';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = 'Geçerli bir e-posta girin.';
        }
        const cleanedPhone = formData.phone.replace(/[^0-9]/g, ''); // Allow only digits
        const phoneRegex = /^(?:\+90|0)?[1-9]\d{2}\d{7}$/; // Matches both formats
        if (!cleanedPhone || !phoneRegex.test(cleanedPhone)) {
            tempErrors.phone = 'Geçerli bir telefon numarası girin.';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const validateStep3 = () => {
        const tempErrors = {};
        if (!formData.cardName) tempErrors.cardName = 'Kart üzerindeki isim gerekli.';
        if (!formData.cardNumber || !/^\d{16}$/.test(formData.cardNumber)) {
            tempErrors.cardNumber = 'Geçerli 16 haneli kart numarası girin.';
        }
        if (!formData.expiryDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiryDate)) {
            tempErrors.expiryDate = 'Geçerli bir son kullanma tarihi girin (MM/YY).';
        }
        if (!formData.securityCode || !/^\d{3}$/.test(formData.securityCode)) {
            tempErrors.securityCode = 'Geçerli 3 haneli CVV kodu girin.';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleNextStep = () => {
        if (step === 1 && validateStep1()) {
            nextStep();
        } else if (step === 2 && validateStep2()) {
            nextStep();
        } else if (step === 3 && validateStep3()) {
            // Handle payment processing here
            console.log('Ödeme Bilgileri:', formData);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="donate-container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6">
                    <div className="row">
                        {/* Summary Section */}
                        <div className="col-md-4">
                            <div className="summary mb-4">
                                <h3>Özet</h3>
                                <ul className="list-group">
                                    {Object.entries(formData).map(([key, value]) => (
                                        <li key={key} className="list-group-item"><strong>{key}:</strong> {value}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* Donation Steps Section */}
                        <div className="col-md-8">
                            <div className="card p-4">
                                <div className="card-body">
                                    {step === 1 && (
                                        <DonationStep1
                                            formData={formData}
                                            handleChange={handleChange}
                                            errors={errors}
                                            nextStep={handleNextStep}
                                        />
                                    )}
                                    {step === 2 && (
                                        <DonationStep2
                                            formData={formData}
                                            handleChange={handleChange}
                                            errors={errors}
                                            prevStep={prevStep}
                                            nextStep={handleNextStep}
                                        />
                                    )}
                                    {step === 3 && (
                                        <DonationStep3
                                            formData={formData}
                                            handleChange={handleChange}
                                            errors={errors}
                                            prevStep={prevStep}
                                            nextStep={handleNextStep}
                                        />
                                    )}
                                    {step > 3 && (
                                        <div className="alert alert-success">Bağış işlemi tamamlandı!</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// 1. Adım: Bağış türü ve tutarı seçimi
const DonationStep1 = ({ formData, handleChange, errors, nextStep }) => {
    return (
        <div>
            <h2>1. ADIM: Bağış Türü ve Tutarını Seçin</h2>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    id="nakit"
                    name="donationType"
                    value="Nakit Bağışı"
                    checked={formData.donationType === 'Nakit Bağışı'}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="nakit">Nakit Bağışı</label>
            </div>
            <div className="mb-3">
                <label htmlFor="amount" className="form-label">Bağış Tutarı:</label>
                <select
                    className="form-select"
                    id="amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                >
                    <option value="">Bağış Paketi Seçin</option>
                    <option value="100">100 TL</option>
                    <option value="1000">1.000 TL</option>
                    <option value="10000">10.000 TL</option>
                    <option value="100000">100.000 TL</option>
                    <option value="1000000">1.000.000 TL</option>
                </select>
                {errors.amount && <div className="text-danger">{errors.amount}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="currency" className="form-label">Döviz Cinsi:</label>
                <select
                    className="form-select"
                    id="currency"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                >
                    <option value="TRY">Türk Lirası</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                </select>
            </div>
            <button className="btn btn-primary w-100" onClick={nextStep}>2. Adıma Geç</button>
        </div>
    );
};

// 2. Adım: Kişisel bilgiler
const DonationStep2 = ({ formData, handleChange, errors, prevStep, nextStep }) => {
    return (
        <div>
            <h2>2. ADIM: Kişisel Bilgilerinizi Girin</h2>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Ad</label>
                <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Adınızı girin"
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="surname" className="form-label">Soyad</label>
                <input
                    className="form-control"
                    type="text"
                    id="surname"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    placeholder="Soyadınızı girin"
                />
                {errors.surname && <div className="text-danger">{errors.surname}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="city" className="form-label">Şehir</label>
                <input
                    className="form-control"
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Şehrinizi girin"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="district" className="form-label">İlçe</label>
                <input
                    className="form-control"
                    type="text"
                    id="district"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    placeholder="İlçenizi girin"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="phone" className="form-label">Telefon Numarası</label>
                <input
                    className="form-control"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Telefon numaranızı girin"
                />
                {errors.phone && <div className="text-danger">{errors.phone}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">E-posta</label>
                <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="E-posta adresinizi girin"
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
            </div>
            <button className="btn btn-secondary" onClick={prevStep}>Geri</button>
            <button className="btn btn-primary w-100" onClick={nextStep}>3. Adıma Geç</button>
        </div>
    );
};

// 3. Adım: Ödeme bilgileri
const DonationStep3 = ({ formData, handleChange, errors, prevStep, nextStep }) => {
    return (
        <div>
            <h2>3. ADIM: Ödeme Bilgilerinizi Girin</h2>
            <div className="mb-3">
                <label htmlFor="cardName" className="form-label">Kart Üzerindeki İsim</label>
                <input
                    className="form-control"
                    type="text"
                    id="cardName"
                    name="cardName"
                    value={formData.cardName}
                    onChange={handleChange}
                    placeholder="Kart üzerindeki ismi girin"
                />
                {errors.cardName && <div className="text-danger">{errors.cardName}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">Kart Numarası</label>
                <input
                    className="form-control"
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    placeholder="16 haneli kart numarasını girin"
                />
                {errors.cardNumber && <div className="text-danger">{errors.cardNumber}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="expiryDate" className="form-label">Son Kullanma Tarihi (MM/YY)</label>
                <input
                    className="form-control"
                    type="text" // Change to 'text' to match the expected format
                    id="expiryDate"
                    name="expiryDate"
                    value={formData.expiryDate}
                    onChange={handleChange}
                    placeholder="MM/YY"
                />
                {errors.expiryDate && <div className="text-danger">{errors.expiryDate}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="securityCode" className="form-label">CVV</label>
                <input
                    className="form-control"
                    type="text"
                    id="securityCode"
                    name="securityCode"
                    value={formData.securityCode}
                    onChange={handleChange}
                    placeholder="3 haneli güvenlik kodunu girin"
                />
                {errors.securityCode && <div className="text-danger">{errors.securityCode}</div>}
            </div>
            <button className="btn btn-secondary" onClick={prevStep}>Geri</button>
            <button className="btn btn-primary w-100" onClick={nextStep}>Bağışı Tamamla</button>
        </div>
    );
};


export default Donate;