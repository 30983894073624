import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Rights from './pages/Rights';
import Branches from './pages/Branches';
import AnnouncementsPage from './pages/AnnouncementPage';
import NewsPage from './pages/NewsPage';
import BankAccount from './pages/BankAccount';
import Donate from './pages/Donate';
import DonatePay from './pages/DonatePay';
import MessageOfPresident from './pages/MessageOfPresident';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ProtectedRoute from './utils/ProtectedRoute';
import AdminPanel from './pages/AdminPanel';
import ProfilePage from './pages/ProfilePage';
import Unauthorized from './pages/Unauthorized';
import './App.css';
import GotoTop from './utils/GotoTop';
import RightsDetails from './pages/RightsDetail';
import NewsDetailPage from './pages/NewsDetailPage';

const App = () => {
  return (
    <Router>
      <div id="root" className="d-flex flex-column min-vh-100">
        <Header />
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rights" element={<Rights />} />
            <Route path="/rights/:id" element={<RightsDetails />} />
            <Route path="/branches" element={<Branches />} />
            <Route path="/announcement" element={<AnnouncementsPage />} />
            <Route path="/announcement/:id" element={<AnnouncementsPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NewsDetailPage />} />
            <Route path="/bankAccount" element={<BankAccount />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/donatepay" element={<DonatePay />} />
            <Route path="/message" element={<MessageOfPresident />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            {/* Protected Routes */}
            <Route path="/adminPanel" element={<ProtectedRoute requiredRole="admin"><AdminPanel /></ProtectedRoute>} />
            <Route path="/user" element={<ProtectedRoute requiredRole="user"><ProfilePage /></ProtectedRoute>} />
          </Routes>
        </main>
        <Footer />
        <GotoTop />
      </div>
    </Router>
  );
};

export default App;
