import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import profile from '../assets/profile.png';
import '../css/Header.css'; // Özel CSS stil dosyanız
import { getProfile } from '../utils/api'; // API çağrısını yapacak fonksiyon
import jwt_decode from 'jwt-decode';

const Header = () => {
    const [user, setUser] = useState(null); // Başlangıçta kullanıcı giriş yapmamış
    const [expanded, setExpanded] = useState(false);
    const navRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate(); // Yönlendirme işlemleri için kullanılıyor
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            fetchUserData();
        }
    }, [token]);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Çıkış yaparken token'ı kaldır
        setUser(null); // Kullanıcıyı null yap
        navigate('/'); // Anasayfaya yönlendir
    };

    const handleClickOutside = (event) => {
        if (navRef.current && !navRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setExpanded(false);
    }, [location]);

    const fetchUserData = async () => {
        const decodedToken = jwt_decode(token);
        try {
            const userData = await getProfile(); // API'den kullanıcı bilgilerini al
            setUser({
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.phone,
                address: userData.address,
                email: userData.email,
                name: decodedToken.username,
                profilePic: userData.profile_pic || profile, // Varsayılan profil resmi
                role: decodedToken.role // Token'dan rol bilgisini al
            });
        } catch (error) {
            console.error('Kullanıcı bilgilerini alırken hata oluştu:', error);
            navigate('/'); // Ana sayfaya yönlendirme
        }
    };

    return (
        <Navbar
            bg="light"
            expand="lg"
            className="shadow-sm"
            expanded={expanded}
            ref={navRef}
        >
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        alt="Vakıf Logo"
                    />
                </Navbar.Brand>

                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(expanded ? false : true)}
                />

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link as={Link} to="/" className="nav-item">Anasayfa</Nav.Link>
                        <Nav.Link as={Link} to="/about" className="nav-item">Hakkımızda</Nav.Link>
                        <Nav.Link as={Link} to="/branches" className="nav-item">Şubelerimiz</Nav.Link>
                        <Nav.Link as={Link} to="/rights" className="nav-item">Haklar</Nav.Link>
                        <Nav.Link as={Link} to="/donate" className="nav-item">Bağış</Nav.Link>
                        <Nav.Link as={Link} to="/contact" className="nav-item">İletişim</Nav.Link>
                        {user && user.role === 'admin' && (
                            <>
                                <Nav.Link as={Link} to="/adminPanel" className="nav-item">AdminPanel</Nav.Link>
                            </>
                        )}
                    </Nav>

                    <div className="d-flex align-items-center ms-3">
                        {user ? (
                            <div className="d-flex align-items-center">
                                <Button as={Link} className="creative-signin-btn me-1" to="/user">
                                    <img
                                        src={user.profilePic}
                                        alt="Profile"
                                        className="rounded-circle me-2 profile-icon"
                                        style={{ width: '30px', height: '30px' }}
                                    />
                                    <span className="profile-name">{`${user.name}`}</span> {/* Kullanıcı adı */}
                                </Button>
                                <Button className="creative-signup-btn" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right me-1" />
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Button as={Link} to="/signin" className="creative-signin-btn me-2" title='Üye Girişi'>
                                    <i className="bi bi-box-arrow-in-right"></i>
                                </Button>
                                <Button as={Link} to="/signup" className="creative-signup-btn" title='Üye Ol'>
                                    <i className="bi bi-person-plus"></i>
                                </Button>
                            </>
                        )}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

export default Header;
