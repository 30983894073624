import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/Donate.css'; // Ensure you create this CSS file for custom styling

const Donate = () => {
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowAlert(true);
        // Handle form submission logic here
    };

    return (
        <Container className="donate-container">
            <h2 className="text-center mb-4">Bağışçımız Ol</h2>

            <Row>
                {/* First Table */}
                <Col xs={12} md={6} lg={3} className="mb-4">
                    <div className="info-section">
                        <h4>Bağışlama Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Hesap Adı:</strong> DENİZBANK - TL<br />
                                <strong>Hesap Numarası:</strong> 2710 - 18332406 - 351<br />
                                <strong>IBAN:</strong> TR45 0013 4000 0183 3240 6000 01<br />
                                <strong>Banka:</strong> DENİZBANK<br />
                                <strong>Şube Adresi:</strong> MİTHATPAŞA ŞUBESİ, Ankara, TR<br />
                            </p>
                        </div>

                        <h4 className="mt-4">İletişim Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Email:</strong> <a href="mailto:info@tusag.com.tr">info@tusag.com.tr</a><br />
                                <strong>Telefon:</strong> (0312) 433 83 80
                            </p>
                        </div>
                    </div>
                </Col>

                {/* Second Table */}
                <Col xs={12} md={6} lg={3} className="mb-4">
                    <div className="info-section">
                        <h4>Bağışlama Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Hesap Adı:</strong> ZİRAAT BANKASI - TL<br />
                                <strong>Hesap Numarası:</strong> 77069895-5001 <br />
                                <strong>IBAN:</strong> TR74 0001 0012 6277 0698 9550 01 <br />
                                <strong>Banka:</strong> ZİRAAT BANKASI <br />
                                <strong>Şube Adresi:</strong> MİTHATPAŞA ŞUBESİ, Ankara, TR<br />
                            </p>
                        </div>

                        <h4 className="mt-4">İletişim Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Email:</strong> <a href="mailto:info@tusag.com.tr">info@tusag.com.tr</a><br />
                                <strong>Telefon:</strong> (0312) 433 83 80
                            </p>
                        </div>
                    </div>
                </Col>

                {/* Third Table */}
                <Col xs={12} md={6} lg={3} className="mb-4">
                    <div className="info-section">
                        <h4>Bağışlama Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Hesap Adı:</strong>  ZİRAAT BANKASI - TL<br />
                                <strong>Hesap Numarası:</strong> 77069895-5002<br />
                                <strong>IBAN:</strong> TR47 0001 0012 6277 0698 9550 02<br />
                                <strong>Banka:</strong> ZİRAAT BANKASI <br />
                                <strong>Şube Adresi:</strong> MİTHATPAŞA ŞUBESİ, Ankara, TR<br />
                            </p>
                        </div>

                        <h4 className="mt-4">İletişim Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Email:</strong> <a href="mailto:info@tusag.com.tr">info@tusag.com.tr</a><br />
                                <strong>Telefon:</strong> (0312) 433 83 80
                            </p>
                        </div>
                    </div>
                </Col>

                {/* Fourth Table */}
                <Col xs={12} md={6} lg={3} className="mb-4">
                    <div className="info-section">
                        <h4>Bağışlama Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Hesap Adı:</strong> ZİRAAT BANKASI - TL<br />
                                <strong>Hesap Numarası:</strong> 77069895-5003 <br />
                                <strong>IBAN:</strong>  TR20 0001 0012 6277 0698 9550 03<br />
                                <strong>Banka:</strong> ZİRAAT BANKASI <br />
                                <strong>Şube Adresi:</strong> MİTHATPAŞA ŞUBESİ, Ankara, TR<br />
                            </p>
                        </div>

                        <h4 className="mt-4">İletişim Bilgileri</h4>
                        <div className="info-box">
                            <p>
                                <strong>Email:</strong> <a href="mailto:info@tusag.com.tr">info@tusag.com.tr</a><br />
                                <strong>Telefon:</strong> (0312) 433 83 80
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Donate;
